.side-menu {
  position: absolute;
  left: 0;

    &__content{
        padding-bottom: 70px;
    }
    &__store-switcher {
        .store-switcher {
            position: relative;
            margin-left: -24px;
            margin-right: -24px;

            &__list {
                display: flex;
            }

            &__item{
                width: 33.333%;
            }

            &__button {
                width: 100%;
                height: 40px;
                position: relative;
                border-bottom: 1px solid #ECECEC;

            }
            &__button--active{
                background-color: #F3F7F9;
                border-bottom: none;
                &:after{
                    display: none;
                }
                &:before{
                    content: "";
                    display: block;
                    width: 0px;
                    height: 0px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #F3F7F9;
                    background: none;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 100%;
                }
            }

            .icon{
                height: 35px;
                width: 100%;
            }
        }
    }

    &__trigger{
        border: none;
        min-width: 32px;
        min-height: 32px;
      background:transparent;
      @include mq($max-screen: $screen-m - 1) {
        min-width: inherit;
        padding:0;
      }
    }

    &__modal {
        background: $side-menu__bg-color;
        justify-content: flex-start;
    }

    &__container {
        margin-top: 0;
        max-width: $side-menu__modal-container-max-width;
        box-shadow: $side-menu__modal-container-box-shadow;
        padding: $side-menu__modal-container-padding;
        max-height: $side-menu__modal-container-max-height;
        @include mq($screen-m) {
            max-height: $side-menu__modal-container-max-height\@medium;
        }
    }

    &__content {
        max-width: 100%;
        margin: $side-menu__modal-content-margin;
        overflow-y: auto;
        @include hide-scrollbar();
    }

    &__close-button {
        position: static;
        margin: 20px 0 0;
        padding: 0;
        background-color: transparent;

        .modal__close-button-icon{
            width: 15px;
            height: 15px;
            position: relative;
            top: -2px;
        }
    }

  .button{
    .side-menu__trigger-icon{
      fill: $gray-dark2;
      width: 26px;
      height: 26px;
    }
  }

    &__cms-block{
        padding: 30px 0;
        border-top: 1px solid #ECECEC;
        .contact-bar{

            &__list{
                display: block;
            }

            &__item{
                margin-top: 16px;
                &:first-child{
                    margin-top: 0;
                }
            }

            &__link{
                font-size: 16px;
                text-transform: uppercase;
                min-height: 20px;
                color: #4E5147;
                line-height: 26px;
                position: relative;
                padding-left: 35px;
                position: relative;
                &:before{
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    left: 0;
                    top: 0;
                    position: absolute;
                    background-color: transparent;
                }

                &.account-link{
                    &:before{
                        background-image: url("../images/icons/user-ext.svg");
                    }
                }

                &.contact-link{
                    &:before{
                        background-image: url("../images/icons/phone-ext.svg");
                    }
                }

                &.trackorder-link{
                    &:before{
                        background-image: url("../images/icons/truck.svg");
                    }
                }

            }

        }
    }

    .dropdown-list{
        &__content{
            display: none;
            .dropdown-list__item--level1{
                .dropdown-list__icon{
                    display: none;
                }
            }
        }
        &--with-nested{
            > .dropdown-list__list{
                > .dropdown-list__item:first-child{
                    border-top: 1px solid #ECECEC;
                }
            }
        }
        &__item{
            border-color: #ECECEC;
        }
        &__label{
            line-height: 14px;
            padding: 22px 56px 18px 0;
            &.active{
                .dropdown-list__icon{
                    transform: rotate(180deg);
                }
            }
        }
        &__icon{
            width: 15px;
            height: 15px;
        }
    }
}
